body {
  text-align: left;
  background: linear-gradient(90deg, rgba(192,221,247,1) 0%, rgba(245,246,209,1) 56%, rgba(190,247,190,1) 100%);
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}
p{
  font-family: 'Roboto', sans-serif;
}

button {
  color:white;
  padding: 8px;
  margin:2px;
  border:none;
  background-color: #349bcd;
  font-size: 22px;
  width:20%;
  border:none;
  cursor:pointer;
}
h1{
  padding:10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* .App{
  text-align: center;
}
body{
  background-color: beige;
}
button {
  margin: 20px;
  background-color:#02192D;  
  border: none;
   color:white;
   cursor: pointer;
   height: 40px;
   font-family:Poppins;
   font-size:20px;
}
input{
  height: 35px;
  margin: 5px;
  width:30%;
} */